import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Seo from 'gatsby-theme-husky/src/common/Seo';
import ColorTopBar from 'gatsby-theme-husky/src/components/ColorTopBar';
import Layout from 'gatsby-theme-husky/src/layout/Layout';
import { GlobalContactForm } from '@phx-husky/global-contact-form';

const ContactPage: FC<ContactTypes.ContactPageProps> = ({
  data: {
    contactPage: { urls, seo, globalForm },
  },
}) => (
  <Layout headerTransparent>
    <Seo {...{ urls, seo }} />
    <ColorTopBar />
    {globalForm ? (
      <GlobalContactForm src={globalForm} id="phx-global-form-gatsby" title="global-contact-form" />
    ) : null}
  </Layout>
);

export const query = graphql`
  query ($url: String, $lang: String) {
    contactPage(url: { eq: $url }, lang: { eq: $lang }) {
      urls {
        lang
        href
      }
      seo {
        ...SEOStructureFragment
      }
      globalForm
    }
  }
`;

export default ContactPage;
